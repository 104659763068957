<mat-nav-list>
    <ng-container *ngFor="let page of pages">
        <mat-list-item *ngIf="!!page.link; else menulist" [routerLink]="page.link">
            <span>{{page.name}}</span>
        </mat-list-item>
        <ng-template #menulist>
            <mat-expansion-panel class="expansion-panel mat-elevation-z0" dense>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{page.name}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list-item *ngFor="let item of page.itens" [routerLink]="item.link">{{item.name}}</mat-list-item>
            </mat-expansion-panel>
        </ng-template>
    </ng-container>
</mat-nav-list>