import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { BaseCollectionService } from '../collections/base-collection.service';
import { faq } from '../models/faq.model';

@Injectable({
  providedIn: 'root'
})
export class FaqService extends BaseCollectionService<faq> {

  protected override collectionName: string = 'FAQ';
  protected override limit: number = 50;
  protected override sortObj = {
    active: "pergunta",
    direction:"asc"
  }

  private lastfaqLoaded:any = null;
  public faqsCount = 0;

  private _faqs$ = new BehaviorSubject<faq[]>([]);
  public faqs$ = this._faqs$.asObservable();

  constructor(protected override fireStore:Firestore) {
    super(fireStore);
    this.getfaqsCount();
    this.getfaqs();
  }

  private async getfaqsCount(){
    this.faqsCount = await this.docCount();
  }

  public async createfaq(faq:faq){
    this.createDoc(faq).then(()=>this.resetLocal())
  }

  public async editfaq(faq:faq, id:string){
    this.editDoc(faq, id).then(()=>this.resetLocal())
  }

  public async getfaqs(searchString: string | null = null){
    if(searchString){
      this._faqs$.next([]);
      this.lastfaqLoaded = null;
    }
    const docs = (await this.getDocs(this.lastfaqLoaded, searchString)).docs;
    if(docs.length > 0){
      this.lastfaqLoaded = docs[docs.length-1];
      const resultsArr = docs.map(val=>Object.assign({} , {...val.data(), id: val.id} as faq));
      // console.log(resultsArr)
      this._faqs$.next([...this._faqs$.value, ...resultsArr]);
    }
  }

  clearSearch(){
    this._faqs$.next([]);
    this.lastfaqLoaded = null;
    this.getfaqs();
  }

  public async excludefaq(id:string){
    await this.deleteDoc(id).then(()=>this.resetLocal());
  }

  private resetLocal(){
      this.lastfaqLoaded = null;
      this._faqs$.next([]);
      this.getfaqs();
      this.getfaqsCount();
  }
}
