<section class="map">
    <span class="text">
        <h2>O Dia de Doar pelo Brasil</h2>
        <p>Ao se inscrever e compartilhar sua iniciativa, você nos ajuda a cobrir o país com generosidade.</p>
        <p>Lembramos que é muito importante registrar as ações que estão sendo pensadas para o Dia de Doar deste ano, e
            não
            um
            registro institucional da sua OSC. Este mapa tem como objetivo inspirar outras instituições através do seu
            exemplo!</p>
    </span>

    <div *ngIf="apiLoaded | async as loaded" class="map-wrapper">
        <!-- google-maps-demo.component.html -->
        <google-map width="100%" [center]="center" [zoom]="zoom" (mapClick)="moveMap($event)"
            (mapMousemove)="move($event)" (zoomChanged)="zoomChanged()">

            <ng-container #GoogleMap *ngIf="partners">
                <map-marker #marker="mapMarker" *ngFor="let partner of partners"
                    [position]="{lat:partner.endereco.latitude, lng:partner.endereco.longitude}"
                    [options]="markerOptions" [clickable]="true" (mapClick)="openInfo(marker, partner)" />
                <map-info-window #info>
                    <ng-container *ngIf="infoContent">
                        <div>{{infoContent.nome}}</div>
                        <span [innerHTML]="infoContent.sobre"></span>
                    </ng-container>
                </map-info-window>
            </ng-container>
        </google-map>

        <!-- <div>Latitude: {{display?.lat}}</div>
        <div>Longitude: {{display?.lng}}</div> -->
    </div>
</section>